#InventorMember {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  /* 각 열의 너비를 설정 */
  table th:nth-child(1),
  table td:nth-child(1) {
    width: 10%; /* 순번 */
  }

  table th:nth-child(2),
  table td:nth-child(2) {
    width: 10%; /* 이름 */
  }

  table th:nth-child(3),
  table td:nth-child(3) {
    width: 10%; /* 영문명 */
  }

  table th:nth-child(4),
  table td:nth-child(4) {
    width: 15%; /* 생년월일 */
  }
  table th:nth-child(5),
  table td:nth-child(5) {
    width: 20%; /* 전화화 */
  }
  table th:nth-child(6),
  table td:nth-child(6) {
    width: 30%; /* 이메일 */
  }

  table th:nth-child(7),
  table td:nth-child(7) {
    width: 25%; /* 주소 */
  }

  table th:nth-child(8),
  table td:nth-child(8) {
    width: 10%; /* 재직 상태 */
  }

  table th:nth-child(9),
  table td:nth-child(9) {
    width: 10%; /* 조작 버튼 */
  }

  .inventor_wrapper {
    width: 880px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      p {
        font-size: 24px;
      }
      button {
        width: 95px;
        font-size: 14px;
        color: white;
        background-color: #0984e3;
        padding: 8px 12px;
        border-radius: 5px;
      }
      .search_bar {
        display: flex;
        height: 40px;
        align-items: center;
        gap: 10px;

        label {
          font-size: 14px;
          font-weight: bold;
          color: #333;
        }

        select {
          padding: 8px 20px 8px 10px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          height: 100%;
          -webkit-appearance: auto; /* 화살표 없애기 for chrome*/
          -moz-appearance: auto; /* 화살표 없애기 for firefox*/
          appearance: auto; /* 화살표 없애기 공통*/
          background: none;
        }
        button {
          width: 50px;
          height: 100%;
        }

        input {
          padding: 8px 10px;
          font-size: 14px;
          width: 240px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
        }
      }
    }
    .table_wrapper {
      height: 500px;
      input[type="text"] {
        height: 100%;
        width: 90%;
      }
      select {
        height: 100%;
        width: 90%;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        font-size: 14px;
        color: black;
        padding: 12px 0px 12px 10px;
      }
    }
  }
}
