@import "src/include";
@import "src/mixin";

#Login {
    margin: auto;
    max-width: 1280px;

    .head {
        margin: auto;
        padding: 50px 0 30px 0;
        max-width: 540px;

        @include mobile {
            padding: 10px 0;
            text-align: center;
        }

        h2 {
            margin-bottom: 10px;
            font-size: 30px;
            font-family: NanumSquareOTF_acEB;
            color: #1d334e;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-family: NanumSquareOTF_acB;
            color: #959595;

            @include mobile {
                font-size: 12px;
            }
        }
    }

    .flex {
        column-gap: 70px;
    }

    .login_form {
        margin: auto;
        width: 100vw;
        max-width: 540px;
        height: 475px;

        @include mobile {
            height: auto;
        }

        .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border: 1px solid #c6c6c6;

            @include mobile {
                position: relative;
                border: none;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
                z-index: 1;
            }

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                width: 50%;
                height: 60px;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                cursor: pointer;
                background-color: rgba(240, 240, 240, 0.15);

                @include mobile {
                    height: 40px;
                    font-size: 14px;
                    color: #443018;
                    background-color: transparent;
                }

                i {
                    width: 36px;
                    height: 36px;

                    @include mobile {
                        display: none;
                    }
                }

                .icon_user_outline {
                    background-image: url("../../assets/images/login/icon-user-outline.svg");
                }

                .icon_business_outline {
                    background-image: url("../../assets/images/login/icon-business-outline.svg");
                }

                &.active {
                    color: #ffffff;
                    background-color: #4593f5;

                    @include mobile {
                        color: #443018;
                        border-bottom: 4px solid #6bd3ff;
                        background-color: transparent;
                    }

                    .icon_user_outline {
                        background-image: url("../../assets/images/login/icon-user-outline-white.svg");
                    }

                    .icon_business_outline {
                        background-image: url("../../assets/images/login/icon-business-outline-white.svg");
                    }
                }
            }
        }

        .form {
            margin-bottom: 10px;
            padding: 30px;
            position: relative;
            width: 100%;
            border: 1px solid #c6c6c6;
            border-top: none;
            box-shadow: 0 5px 8px 0 rgb(68 68 68 / 4%);
            background-color: #ffffff;

            @include mobile {
                border: none;
                box-shadow: none;
            }

            .input_area {
                position: relative;
                z-index: 1;

                i {
                    position: absolute;
                    top: 15px;
                    left: 15px;

                    @include mobile {
                        top: 10px;
                    }
                }
            }

            .icon_login_user_id {
                background: url("../../assets/images/login/ic-user-default.svg") no-repeat 50% 50%;
            }

            .icon_login_lock_pw {
                background: url("../../assets/images/login/ic-lock-default.svg") no-repeat 50% 50%;
            }

            .id_area:focus-within {
                z-index: 2;
                i {
                    background: url("../../assets/images/login/ic-user-blue.svg") no-repeat 50% 50%;
                }
            }

            .pw_area:focus-within {
                z-index: 2;
                i {
                    background: url("../../assets/images/login/ic-lock-blue.svg") no-repeat 50% 50%;
                }
            }

            input {
                padding: 0 50px;
                width: 100%;
                height: 60px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.75);
                border: 1px solid #d8d8d8;

                @include mobile {
                    height: 45px;
                    font-size: 14px;
                }
            }

            input:focus {
                border: 1px solid #2d99ff !important;
            }

            .input_wrap {
                box-shadow: 0 2px 8px 0 rgb(0, 0, 0, 0.05);

                .input_area:last-child {
                    margin-top: -1px;
                }
            }

            .btn_kakao {
                margin: 25px auto 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 8px;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                background-color: #ffffff;

                i {
                    width: 49px;
                    height: 49px;
                    background-size: cover;
                }
            }

            .btn_login {
                margin-top: 22px;
                display: block;
                width: 100%;
                height: 50px;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #ffffff;
                background-color: #4593f5;
            }
        }

        .error {
            margin-top: 22px;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0.5px;
            text-align: center;
            color: var(--color-Alizarin-Crimson);
        }
    }

    .account_search {
        margin: 22px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            font-size: 14px;
            color: #959595;

            @include mobile {
                font-size: 12px;
            }
        }

        .line {
            margin: 0 18px;
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: #dadada;
        }
    }

    @include mobile {
        padding-top: 60px;
        padding-bottom: 40px;
    }
}
