@import "src/include";

#PatentView {
    margin: 36px auto 0 auto;
    width: 1280px;

    @import "../common/css/ListView";

    .border_blue {
        border-bottom: solid 1px var(--color-Niagara);
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
    }

    .wrap_btn_add {
        position: relative;
    }

    .btn_add:hover + .btn_desc {
        display: block;
    }

    .btn_desc {
        padding: 20px 11px;
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        min-width: 285px;
        width: 19vw;
        max-width: 400px;
        font-size: 14px;
        @include border-radius(9px);
        background-color: rgba(0, 0, 0, 0.85);

        h2 {
            margin-bottom: 8px;
            font-family: NanumSquareOTF_acEB;
            color: var(--color-Web-Orange);
        }

        p {
            line-height: 21px;
            color: #ffffff;
        }
    }

    table {
        th {
            height: 40px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            word-break: break-all;
        }
        thead {
            background-color: var(--color-Cloud-Burst);
        }
        th {
            .more {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
            }
        }

        td.manage_number {
            word-break: keep-all;
        }
    }
}
