$labelWidth: 100px;
$borderColor: rgb(203, 184, 184);

.form_history_container {
  padding: 30px;
  border-radius: 20px;
  height: 90vh;
  max-height: 740px;

  // global style
  i {
    cursor: pointer;
  }

  .mandatory {
    font-size: 1.13rem;
    color: red;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  & > div {
    overflow-y: auto;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    & > div {
      display: flex;
      cursor: pointer;
    }
    h1 {
      font-size: 1.3rem;
    }
  }

  form#history_form {
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 30px;
    flex-grow: 1;

    .collapsable_fields {
      display: flex;
      gap: 20px;
      flex-direction: column;
      overflow: hidden;
    }

    .dates_div {
      display: flex;
      justify-content: space-between;

      .field_div {
        flex-basis: 150px;

        .react-datepicker-popper {
          z-index: 15;
        }

        & > .react-datepicker-wrapper {
          margin-top: 8px;
        }

        input {
          width: 100%;
          padding: 5px;
          height: auto !important;
        }
      }
    }
    .deadline_type {
      display: flex;

      & > label {
        width: $labelWidth;
      }

      & > div {
        display: flex;
        flex-grow: 1;
        justify-content: space-around;

        & > div {
          display: flex;
          gap: 8px;
        }
      }
    }

    .job_title {
      flex-grow: 1;
      display: flex;

      & > .important {
        &,
        input {
          background-color: red;
          color: white;
        }
      }

      & > label {
        width: $labelWidth;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
        border: 1px solid $borderColor;
        border-radius: 5px;
        padding: 0px 5px;

        & > input {
          flex-grow: 1;
          border: none;
        }

        div {
          display: flex;
          gap: 8px;
        }
      }
    }

    .attachment_div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > div:first-child {
        display: flex;
        justify-content: space-between;

        & > span {
          width: $labelWidth;
          cursor: pointer;
          align-self: flex-start;
          display: flex;
          align-items: center;
        }

        .drag_zone {
          border: 1px solid $borderColor;
          border-radius: 5px;
          text-align: center;
          padding: 12px 8px;
          cursor: pointer;
          flex-grow: 1;
        }
      }

      & > div:last-child {
        max-height: 220px;
        overflow-y: auto;

        & > div {
          min-height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: italic;

          &.loading {
            font-style: italic;
            font-size: 0.9rem;
            color: green;
          }
        }

        .attchment_table {
          thead {
            background-color: red;
          }
        }
        .delete_attachment {
          color: red;
          background-color: transparent;
          font-size: small;
          font-style: italic;
        }
      }
    }

    .comments_div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-grow: 1;

      .sun-editor {
        .se-toolbar-sticky-dummy {
          display: none;
        }

        .se-list-layer,
        .se-submenu {
          top: unset !important;
        }

        &,
        .se-container {
          display: flex !important;
          flex-direction: column !important;
          flex-grow: 1 !important;

          .se-wrapper {
            flex-grow: 1;

            .se-wrapper-inner {
              height: 100% !important;
            }
          }
          .se-toolbar {
            position: static !important;
          }
        }

        &,
        .se-toolbar {
          border-radius: 5px;
        }

        .se-toolbar button {
          font-size: 12px;
          width: 28px;
          height: 28px;

          svg {
            width: 12px;
          }
        }
      }
    }

    .submit_controls {
      display: flex;
      gap: 10px;
      justify-content: center;
      border-top: none;

      hr {
        height: 2px;
        background-color: #888;
        margin: 10px 0;
      }
      button {
        color: white;
        padding: 10px 20px;
      }
      button[type="submit"] {
        background-color: #96c718;
      }
      button[type="reset"] {
        background-color: #19c77c;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 3px; /* Width of scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background: #4593f5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #1e59a1;
  }
}
