#MenuTab {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  //border-bottom: 1px solid var(--color-Cornflower-Blue);
  z-index: 2;

  .wrap {
    margin: auto;
    width: 1280px;
    .tab_list_wrapper_login {
      width: 1280px;
      display: flex;
      justify-content: space-around;
      padding-right: 200px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        width: 160px;
        font-family: NanumSquareOTF_acEB;
        font-size: 18px;
        color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          line-height: 41px;
          white-space: pre;
        }
      }
      .bold a,
      .active a {
        border-bottom: 3px solid #4593f5;
        line-height: 35px;
        margin-top: 3px;
      }
      .bold_ipnow a,
      .active_ipnow a {
        border-bottom: 3px solid #f6a310;
      }
      .child_tab_list > li {
        a {
          border-bottom: inherit;
        }
      }
      .a_ipnow:hover {
        border-bottom: 3px solid #f6a310;
        line-height: 35px;
        margin-top: 3px;
      }
      .a_biznavi:hover {
        border-bottom: 3px solid #4593f5;
        line-height: 35px;
        margin-top: 3px;
      }
    }
    .tab_list_wrapper {
      width: 1280px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      .tab_list:not(#Intro_tab_list) {
        > li {
          width: 140px;
        }
      }
    }
    .tab_list_service {
      display: flex;
      align-items: center;
      a {
        font-size: 14px;
        text-align: center;
        padding-left: 10px;
        height: 18px;
        color: #606060;
      }
      .active {
        color: black;
      }
      h2 {
        padding: 0 2px 0 2px;
        color: #f0f0f0;
      }
      .btn_change {
        width: 123px;
        height: 26px;
        margin-left: 2px;
        p {
          padding: 2px 0;
          width: 107px;
          font-size: 12px;
          font-family: NanumSquareOTF_acR;
          span {
            font-family: NanumSquareOTF_acEB;
          }
        }
      }
      .btn_findbiz {
        color: #4792f7;
        background-color: rgba(107, 211, 255, 0.2);
      }
      .btn_ipnow {
        color: #ffa600;
        background-color: rgba(255, 166, 0, 0.2);
      }
    }
    #IntroMenu {
      .intro_link {
        margin: auto;
        width: 1280px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          cursor: pointer;
        }

        div {
          font-size: 18px;
        }

        div:first-child {
          margin-right: 90px;
          text-align: left;
        }

        .sign {
          width: auto;
          display: flex;
          align-items: center;
          column-gap: 30px;

          a {
            line-height: 36px;
            display: block;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
          }

          .btn_signup {
            width: 144px;
            height: 42px;
            border-radius: 8px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: NanumSquareOTF_acEB;
          }

          .btn_login {
            font-family: NanumSquareOTF_acB;
            font-size: 16px;
            display: block;
            color: #343434;
            text-align: center;
          }
        }

        .bold a {
          font-family: NanumSquareOTF_acB;
        }
      }

      .tab_list {
        li {
          a {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            text-transform: uppercase;
          }

          &:hover {
            .child_tab_list {
              display: block !important;
            }
          }
        }
      }
    }
  }

  .child_tab_list {
    position: absolute;
    top: 100%;
    left: 0;
    display: none !important;
    width: 100% !important;
    box-shadow: 0 2px 4px 0 #f0f0f0;
    border: solid 1px #f0f0f0;

    li {
      display: flex;
      align-items: center;
      width: 100% !important;
      height: 40px !important;
      font-size: 14px !important;
      font-family: NanumSquareOTF_acB;
      background-color: #ffffff;

      a {
        padding-left: 20px;
        width: 100%;
        height: 100%;
        text-align: left;
      }
    }
  }

  .btn_change {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 28px;
    font-size: 14px;
    font-family: NanumSquareOTF_acB;
    border-radius: 20px;
    color: #ffffff;
  }

  .btn_ipnow {
    background-color: var(--color-Web-Orange);
  }

  .btn_findbiz {
    background-color: #6bd3ff;
  }
}
