#HistoryPopup {
    width: 700px;
    height: 700px;
    padding: 43px 61px;
    background-color: #fff;
    border: 1px solid #606060;
    border-radius: 10px;
    z-index: 9999;
  
    .deadline_type {
      width: 100%;
      display: flex;
      justify-content: space-around;
  
      input[type="radio"] {
        width: 20px;
        height: 20px;
      }
  
      & > div {
        display: flex;
        gap: 4px;
      }
    }
  
    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        font-size: 20px;
      }
      i {
        cursor: pointer;
      }
    }
    section {
      ul > li {
        display: flex;
        align-items: center;
        margin: 20px 0;
        > p {
          min-width: 120px;
        }
        > div {
          display: flex;
          align-items: center;
          > p {
            min-width: 110px;
          }
        }
        input[type="text"] {
          width: 100%;
        }
        textarea {
          height: 300px;
          border: solid 1px rgba(0, 0, 0, 0.56);
        }
      }
    }
    footer {
      padding-top: 20px;
      button {
        display: block;
        margin: 0 auto;
        height: 32px;
        padding: 0 30px;
        letter-spacing: 0.86px;
        color: #fff;
        font-family: NanumSquareOTF_acB;
        font-size: 12px;
        border-radius: 4px;
        background-color: #4593f5;
      }
    }
  }
  