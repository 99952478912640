#IpStatistic {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 0;
  strong {
    font-family: "Pretendard_extraBold";
    font-size: 18px;
  }
  .section {
    margin-bottom: 30px;
  }
  .round_box {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
  }
  .sub_tit {
    display: block;
    margin-bottom: 20px;
    .desc {
      padding-left: 14px;
      font-family: "Pretendard_light";
      font-size: 16px;
    }
  }
  .top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .ip_num {
    > ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 42px;
      > li {
        text-align: center;
        strong {
          color: #0078f1;
          font-size: 32px;
        }
        &:first-of-type {
          margin-right: 60px;
        }
      }
    }
  }
  .avg_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .grade {
      display: inline-block;
    }
    .total_avg {
      font-size: 28px;
      padding: 10px 20px;
      border-radius: 40px;
      margin-right: 40px;
    }
    > ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      > li {
        text-align: center;
        > strong {
          min-width: 80px;
          padding: 4px 8px;
          border-radius: 20px;
          margin-top: 4px;
        }
      }
    }
  }
  .mid_1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }
  .mid_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  .status_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    justify-items: center;
    button:not(.link) {
      padding: 10px 14px;
      border-radius: 6px;
      background: rgb(0, 120, 241, 0.7);
      color: #fff;
      margin-right: 6px;
      &.on {
        background: rgb(0, 120, 241, 1);
      }
    }
    .cate_box {
      display: block;
      width: 100%;
      > div {
        display: flex;
        align-items: center;
        > p {
          width: 80px;
        }
      }
      .cate {
        margin-bottom: 20px;
        > ul {
          display: flex;
          align-items: center;
        }
      }
      .keyword {
        .search {
          margin-left: 6px;
        }
      }
    }
    .keyword {
      margin-bottom: 20px;
    }
  }
  .chart {
    // width: 400px;
  }
  .keyword_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > li {
      padding: 10px 14px;
      border-radius: 6px;
      background: #b8b8b8;
      color: #fff;
      margin-right: 6px;
      margin-bottom: 6px;
      cursor: pointer;
      &.on {
        background-color: #0078f1;
      }
    }
  }
  .no_data {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  .tbl_box {
    max-height: 263px;
    overflow: auto;
    margin-top: 30px;
    table {
      width: 100%;
      th {
        position: sticky;
        top: 0;
        background-color: #f0f0f0;
      }
    }
  }
  .selected {
    padding: 10px 14px;
    border-radius: 6px;
    background: #0078f1;
    color: #fff;
    margin-right: 6px;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      width: 16px;
      height: 16px;
      background-size: contain;
      margin-left: 6px;
    }
  }
  .modal {
    z-index: 9999;
  }
  .distribution_tbl,
  .inventor {
    tbody {
      > tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(107, 211, 255, 0.2);
        }
      }
    }
  }
}
