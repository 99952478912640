@import "src/include";

#ExpectaionList {
  margin: auto;
  padding-top: 46px;
  width: 95%;

  .table-box-wrap {
    padding-top: 46px;
    > .wrap_list {
      max-height: inherit;
    }
  }

  .second_header {
    display: flex;
    justify-content: space-between;
    .second_header_right {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .search_wrapper {
      display: flex;
      align-items: center;
      width: 423px;
      height: 51px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 30px;
      padding: 0 10px;
      justify-content: space-between;
      select {
        width: 25%;
        padding: 0 10px;
        color: #343434;
        letter-spacing: -0.09px;
        font-family: Pretendard_regular;
      }
      input[type="text"] {
        width: 65%;
        border: none;
      }
      i {
        margin-left: 10px;
      }
    }
    .count_wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      select {
        width: 70px;
        border: 1px solid #d8d8d8;
      }
    }
  }
  .wrap {
    margin: 0 35px 39px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn_search_name {
      padding: 0 20px;
      height: 30px;
      border-radius: 4px;
      background-color: #4593f5;
      color: #fff;
      margin-right: 10px;
    }
    .btns {
      display: flex;
    }
    .btn_back {
      width: 150px;
      height: 32px;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
      border-radius: 4px;
      color: #ffffff;
      background-color: #e92f2c;
      margin-left: 20px;
    }
    .btn_tab_setting,
    .btn_tab_setting_save {
      width: 150px;
      height: 32px;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
      border: solid 0.5px #959595;
      background-color: #ffffff;
      color: black;
      margin-left: 20px;
    }
  }

  .annual_change_view {
    width: 100%;
    display: flex;
    font-family: NanumSquareOTF_acB;
    font-size: 16px;
    color: #343434;
    margin-bottom: 24px;
    > p {
      width: 50%;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 15px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 22px;
    font-family: NanumSquareOTF_acB;
  }

  .circle {
    margin: auto;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .red {
    background-color: #e72222;
  }

  .yellow {
    background-color: #ffa627;
  }

  .green {
    background-color: #05ac9e;
  }

  .gray {
    background-color: #e7e7e7;
  }

  .wrap_table + .wrap_table {
    margin-top: 41px;
  }

  .wrap_list {
    table {
      thead {
        border-bottom: solid 1px #959595;
        background-color: #f0f0f0;
        height: 46px;
        th {
          color: #343434;
          height: 46px;
          .more {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
          }
          ul {
            overflow-y: scroll;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 150px;
            background-color: white;
            color: black;
            border: 1px solid rgb(0, 149, 174);
            z-index: 2;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            li {
              padding: 4px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 32px;
              font-size: 12px;
              font-family: NanumSquareOTF_acR;
              cursor: pointer;
              span {
                font-size: 12px;
                text-align: left;
              }
            }

            li + li {
              border-top: 1px solid rgb(0, 149, 174);
            }
          }
          ul::-webkit-scrollbar {
            display: none;
          }
        }
        th.dp_none {
          ul {
            display: none;
          }
        }
        .nation_select {
          width: 100%;
          height: 100%;
          background-color: inherit;
          text-align: center;
          border: none;
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
          color: #343434;
          outline: none;
        }
      }
      tbody {
        .button_review {
          width: 46px;
          height: 18px;
          background-color: rgba(255, 166, 39, 0.2);
          border-radius: 5px;
          font-family: NanumSquareOTF_acB;
          font-size: 10px;
          letter-spacing: 0.71px;
          color: #343434;
          z-index: 100;
        }
        .annual_payment {
          button {
            width: 40%;
            height: 24px;
            font-family: NanumSquareOTF_acB;
            font-size: 12px;
            letter-spacing: 0.8px;
            color: #fff;
            border-radius: 9px;
          }
          button:nth-child(1) {
            background-color: #e92f2c;
          }
          button:nth-child(2) {
            background-color: #05ac9e;
            margin-left: 5px;
          }
          button:nth-child(3) {
            width: 80%;
            background-color: #959595;
            font-size: 11px;
            margin-top: 10px;
          }
          p {
            font-family: NanumSquareOTF_acR;
            font-size: 12px;
            letter-spacing: -0.4px;
          }
        }
        .annual_receipt {
          p {
            font-family: NanumSquareOTF_acEB;
            font-size: 12px;
            letter-spacing: -0.4px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 4px;
          }
          button {
            width: 74px;
            height: 18px;
            border-radius: 5px;
            background-color: rgba(240, 240, 240, 0.8);
            font-family: NanumSquareOTF_acB;
            font-size: 10px;
            letter-spacing: 0.71px;
            color: #343434;
          }
        }
        .perCost {
          width: 80px;
          background-color: #0095ae;
          padding: 3px;
          color: white;
          border-radius: 5px;
          font-size: 12px;
        }
        .perCost.not_match {
          background-color: #eeeeee;
          color: black;
        }
        .report {
          background-color: #6698cb;
          padding: 3px;
          width: 70%;
          color: white;
          border-radius: 5px;
          font-size: 12px;
        }
        .nonreport {
          background-color: #ced4da;
          padding: 3px;
          width: 70%;
          color: white;
          border-radius: 5px;
          font-size: 12px;
        }
        tr {
          height: 51px;
        }
        tr:not(.not_styled):hover {
          background-color: rgba(0, 149, 174, 0.1);
          transition-duration: 0.5s;
          cursor: pointer;
        }
      }
    }
    .patent_table {
      width: 1280px;
      td:nth-child(1),
      th:nth-child(1) {
        width: 60px;
      }
      td:nth-child(2),
      th:nth-child(2) {
        width: 120px;
        text-align: left;
      }
      td:nth-child(3),
      th:nth-child(3) {
        width: 70px;
      }
      td:nth-child(4),
      th:nth-child(4) {
        width: 80px;
      }
      td:nth-child(5),
      th:nth-child(5) {
        width: 120px;
      }
      td:nth-child(6),
      th:nth-child(6) {
        width: 120px;
      }
      td:nth-child(7),
      th:nth-child(7) {
        width: 120px;
      }
      td:nth-child(8),
      th:nth-child(8) {
        width: 100px;
      }
      td:nth-child(9),
      th:nth-child(9) {
        width: 100px;
      }
      td:nth-child(10),
      th:nth-child(10) {
        width: 70px;
      }
      td:nth-child(11),
      th:nth-child(11) {
        width: 50px;
      }
      td:nth-child(13),
      th:nth-child(13) {
        width: 100px;
      }
      th:nth-child(12),
      th:nth-child(14) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .trademark_table {
      td:nth-child(1),
      th:nth-child(1) {
        width: 60px;
      }
      td:nth-child(2),
      th:nth-child(2) {
        width: 120px;
        text-align: left;
      }
      td:nth-child(3),
      th:nth-child(3) {
        width: 70px;
      }
      td:nth-child(4),
      th:nth-child(4) {
        width: 120px;
      }
      td:nth-child(5),
      th:nth-child(5) {
        width: 120px;
      }
      td:nth-child(6),
      th:nth-child(6) {
        width: 120px;
      }
      td:nth-child(7),
      th:nth-child(7) {
        width: 120px;
      }
      td:nth-child(8),
      th:nth-child(8) {
        width: 100px;
      }
      td:nth-child(9),
      th:nth-child(9) {
        width: 100px;
      }
      td:nth-child(10),
      th:nth-child(10) {
        width: 70px;
      }
      td:nth-child(12),
      th:nth-child(12) {
        width: 120px;
      }
      th:nth-child(11),
      th:nth-child(13) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .select_wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 0 10px;
    margin: 0 0 20px 35px;
    select:nth-of-type(1) {
      width: 80px;
    }
    select:nth-of-type(2) {
      width: 60px;
    }
  }
  .comp_frame {
    width: 1390px;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border: 1px solid #eee;
    .close {
      text-align: right;
      padding: 20px 20px 0 0;
    }
  }
}
