.toggle-content {
  overflow: hidden;
  transition: max-height 0.5s ease;
  padding: 20px;
  .toggle-content-children {
    padding-top: 30px;
    padding-bottom: 20px;
    // border-bottom: 2px solid #f9fafb;
  }
}
