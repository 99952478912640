#UserManage {
    margin: auto;
    padding-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    width: 1280px;

    .wrap_user_manage {
        width: 880px;
    }

    .area_box {
        margin: 0 auto 24px;
        padding: 35px 45px;
        width: 880px;
        height: 140px;
        border-radius: 20px;
        background-color: rgba(241, 241, 241, 0.3);
        &_text{
            position: relative;
            padding-left: 120px;
            &_title{
                font-family: NanumSquareOTF_acB;
                font-size: 18px;
                margin-bottom: 10px;
            }
            &_info{
                font-size: 14px;
                line-height: 1.3;
                color: #666;
            }
            &::after{
                position: absolute;
                content: '';
                width: 70px;
                height: 70px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: url(../../../assets/images/home/bg_change.svg) no-repeat 50% 50%;
                background-size: cover;
            }
        }
    }

    .manage_user_wrap_table:nth-child(-n + 4) {
      margin-top: 73px;
    }
    .manage_user_wrap_table:nth-child(1) {
        margin-top: 38px;
    }

    .btn {
        font-family: NanumSquareOTF_acB;
    }

    .btn_add {
        padding: 0 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        color: white;
        text-align: center;
        background-color: var(--color-Web-Orange);

        span {
            display: block;
            width: 85px;
            pointer-events: none;
        }
    }
    .btn_add_complete {
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        color: white;
        text-align: center;
        background-color: #19c77c;
    }

    .ManageUserTable + .ManageUserTable {
        margin-top: 30px;
    }
}

#UserManage.one {
    .ManageUserTable:last-child {
        display: none;
    }
}

#UserManage.two {
    .ManageUserTable {
        display: none;
    }

    .ManageUserTable:last-child {
        display: block;
    }
}