.ContentLayout {
  border-bottom: 2px solid #ededed;
  padding-bottom: 20px;
  position: relative;
  & + .ContentLayout {
    margin-top: 30px;
  }
  &.border_none {
    border-bottom: none;
    padding: 20px;
  }
  .table_layout {
    display: flex;
    align-items: center;
    gap: 20px;
    > div {
      flex: none;
      width: 40%;
      &.korean {
        width: 30%;
      }
    }
  }
  .layout_tit {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
  }
}
