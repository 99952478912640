#ExpectationAnnual {
    margin: auto;
    padding-top: 42px;
    width: 1280px;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        overflow-y: scroll;
        margin-top: 50px;
    }
    .area_box {
        margin: 0 auto 24px auto;
        padding: 26px 0 25px 45px;
        width: 880px;
        height: 140px;
        border-radius: 20px;
        background-color: rgba(241, 241, 241, 0.3);
    }

    > .header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .info {
            margin-left: 135px;
            display: flex;
            flex-direction: column;
            column-gap: 14px;

            h2 {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
            }

            p {
                display: flex;
                align-items: center;
                column-gap: 7px;
                font-size: 16px;
                line-height: 21px;
                color: rgba(0, 0, 0, 0.87);

                span {
                    font-size: 12px;
                    color: #606060;
                }
            }
        }
        .search_inventor {
            width: 153px;
            height: 40px;
            border-radius: 4px;
            background-color: #4593f5;
            color: #fff;
            margin-right: 10px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 0;
            display: block;
            .info {
                margin-left: 0;
                display: block;
                h2 {
                    font-size: 16px;
                    text-align: center;
                }
                p {
                    justify-content: center;
                }
            }
        }
    }

    .flex {
        margin-bottom: 49px;
        justify-content: space-between;
        height: 25px;
        .annual_fee_button {
            display: flex;
            align-items: center;
            margin-left: 135px;
            p {
                font-family: NanumSquareOTF_acB;
                font-size: 16px;
                color: #343434;
            }
            button {
                margin-left: 10px;
                width: 95px;
                height: 26px;
                border-radius: 4px;
                border: solid 2px #606060;
                font-family: NanumSquareOTF_acB;
                font-size: 12px;
                letter-spacing: -0.4px;
                color: #343434;
                background-color: #fff;
            }
        }
        @media screen and (max-width: 768px) {
            margin-top: 20px;
            flex-direction: column;
            .annual_fee_button {
                margin-left: 0;
                justify-content: center;
            }
            .legend {
                margin-top: 10px;
                justify-content: center;
            }
        }
    }

    .legend {
        display: flex;
        align-items: center;
        column-gap: 24px;

        .item {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .circle {
                margin-right: 10px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
            }

            .blue {
                background-color: #4593f5;
            }

            .main_color {
                background-color: var(--color-Web-Orange);
            }

            p {
                letter-spacing: 10px;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
            }
        }
    }
}
